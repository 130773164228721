"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { ExploreCard } from "./ExploreCard";

export function Explore({
  as: _Component = _Builtin.Block,
  exploreComponentSlot,
}) {
  return (
    <_Component className="explore_component" tag="div">
      {exploreComponentSlot ?? (
        <>
          <ExploreCard
            image="https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/661567ea03a6003b067feff4_preston%20at%20m%20mile.jpeg"
            altText="__wf_reserved_inherit"
            link={{
              href: "/search/?www.srgliving.com[refinementList][type][0]=multiFamily",
            }}
          />
          <_Builtin.Block className="explore_spacer" tag="wrapper" />
          <ExploreCard
            link={{
              href: "/search/?www.srgliving.com%5BrefinementList%5D%5Btype%5D%5B0%5D=senior",
            }}
            image="https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/66154e9865c9d61893023c40_55%2B.jpeg"
            text="55+ and Better"
            altText="custom alt tag here"
          />
          <_Builtin.Block className="explore_spacer" tag="wrapper" />
          <ExploreCard
            text="Single Family Residences & Townhomes"
            image="https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/66154e98b7c1ab00e31f338f_singlefamily.jpeg"
            link={{
              href: "/search/?www.srgliving.com%5BrefinementList%5D%5Btype%5D%5B0%5D=singleFamily",
            }}
          />
        </>
      )}
    </_Component>
  );
}
