"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function MattersCard({
  as: _Component = _Builtin.Block,
  iconImage = "https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/656bfa5eeb3430d878b88ea5_Vectors-Wrapper.svg",
  text = "We Care Deeply",
}) {
  return (
    <_Component className="matters_card" tag="div">
      <_Builtin.Block className="maters_icon" tag="div">
        <_Builtin.Image
          className="icon-1x1-large"
          loading="lazy"
          width="22"
          height="20"
          alt=""
          src={iconImage}
        />
      </_Builtin.Block>
      <_Builtin.Block className="text-size-medium" tag="div">
        {text}
      </_Builtin.Block>
    </_Component>
  );
}
