"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function RegionalPageHeader({
  as: _Component = _Builtin.Block,
  image = "https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/65e644bc47b36e79497894ed_lifestyle-city-2000x1000.jpg",
  text = "Phoenix Apartment Living",
  showHero = true,
}) {
  return showHero ? (
    <_Component className="header_lp" tag="header">
      <_Builtin.Block className="padding-global" tag="div">
        <_Builtin.Block className="container-small" tag="div">
          <_Builtin.Block className="padding-section-large" tag="div">
            <_Builtin.Block className="text-align-center" tag="div">
              <_Builtin.Heading className="text-color-white" tag="h1">
                {text}
              </_Builtin.Heading>
            </_Builtin.Block>
          </_Builtin.Block>
        </_Builtin.Block>
      </_Builtin.Block>
      <_Builtin.Block className="header69_background-image-wrapper" tag="div">
        <_Builtin.Block className="image-overlay-layer" tag="div" />
        <_Builtin.Image
          className="header69_background-image"
          loading="eager"
          width="auto"
          height="auto"
          fetchPriority="high"
          alt=""
          src={image}
        />
      </_Builtin.Block>
    </_Component>
  ) : null;
}
