"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { CardIconPet } from "./CardIconPet";
import { CardIconDogPark } from "./CardIconDogPark";
import { CardIconPool } from "./CardIconPool";
import { CardIconFitness } from "./CardIconFitness";
import { CardIconWashDry } from "./CardIconWashDry";
import { CardIconEvCharging } from "./CardIconEvCharging";
import { CardIconCoveredParking } from "./CardIconCoveredParking";
import { CardIconPrivateGarage } from "./CardIconPrivateGarage";

export function PropertyCardFullWidth({
  as: _Component = _Builtin.Block,
  name = "This is the default text value",
  address = "10001 West View Ave, Sacramento, California",
  bedrooms = "1, 2, 3",
  startingPrice = "$1,111",
  amenitySlot,
  slideSlot,
  linkProps,
}) {
  return (
    <_Component className="horizontal-card" tag="div">
      <_Builtin.Block className="card_asset-large-format" tag="div">
        {slideSlot ?? (
          <_Builtin.Block className="card_slider-image-wrapper" tag="div">
            <_Builtin.Image
              className="card_slider-image"
              loading="lazy"
              width="232"
              height="159"
              src="https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/65dfccab65dbeca288e60830_goldenwest-default.jpg"
            />
          </_Builtin.Block>
        )}
      </_Builtin.Block>
      <_Builtin.Block className="copy" tag="div">
        <_Builtin.Block className="name-address" tag="div">
          <_Builtin.Heading className="name-full-width" tag="h2">
            {name}
          </_Builtin.Heading>
          <_Builtin.Block className="frame-214" tag="div">
            <_Builtin.Image
              className="vectors-wrapper-15"
              loading="lazy"
              width="9"
              height="13"
              aria-hidden="true"
              src="https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/65728687c8a1b4c6aab7697d_Vectors-Wrapper.svg"
            />
            <_Builtin.Paragraph className="text-51">
              {address}
            </_Builtin.Paragraph>
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block className="beds-and-starting-price" tag="div">
          <_Builtin.Block className="beds" tag="div">
            <_Builtin.Paragraph className="bedlabel">
              {"bedrooms"}
            </_Builtin.Paragraph>
            <_Builtin.Paragraph className="text-52">
              {bedrooms}
            </_Builtin.Paragraph>
          </_Builtin.Block>
          <_Builtin.Block className=" beds" tag="div">
            <_Builtin.Paragraph className=" bedlabel">
              {"Starting at"}
            </_Builtin.Paragraph>
            <_Builtin.Paragraph className=" text-52">
              {startingPrice}
            </_Builtin.Paragraph>
          </_Builtin.Block>
        </_Builtin.Block>
        <_Builtin.Block className="amenities-large" tag="div">
          {amenitySlot ?? (
            <>
              <CardIconPet />
              <CardIconDogPark />
              <CardIconPool />
              <CardIconFitness />
              <CardIconWashDry />
              <CardIconEvCharging />
              <CardIconCoveredParking />
              <CardIconPrivateGarage id="w-node-c0cbd11c-e988-82a6-8710-ab93c5782037-6ea2841a" />
            </>
          )}
        </_Builtin.Block>
        <_Builtin.Block className="frame-215" tag="div">
          <_Builtin.Link
            className="frame-216"
            button={false}
            block=""
            options={linkProps}
          >
            <_Builtin.Paragraph className="text-53">
              {"View Home"}
            </_Builtin.Paragraph>
          </_Builtin.Link>
        </_Builtin.Block>
      </_Builtin.Block>
    </_Component>
  );
}
