"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { MattersCard } from "./MattersCard";

export function MattersCardsGrid({
  as: _Component = _Builtin.Block,
  mattersCardWrapperSlot,
}) {
  return (
    <_Component className="matters_card-wrapper" tag="div">
      {mattersCardWrapperSlot ?? (
        <>
          <MattersCard iconImage="https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/657b197ada7d32e45dd83176_heart.svg" />
          <MattersCard
            text="Your Home Matters"
            iconImage="https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/657b190a79e90671086314d4_Layer_2.svg"
          />
          <MattersCard
            text="Built On Trust"
            iconImage="https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/657b191b78f7fd35ce1bda0a_Layer_2-1.svg"
          />
          <MattersCard
            text="Lease With Confidence"
            iconImage="https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/657b192ab73350a82af6808e_Layer_1.svg"
          />
          <MattersCard
            text="Actions Speak Louder"
            iconImage="https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/657b193d387e970e54128cca_Layer_2-2.svg"
          />
          <MattersCard
            text="We Are A Community"
            iconImage="https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/657b19511563f1bffd7d5881_Layer_2-3.svg"
          />
        </>
      )}
    </_Component>
  );
}
