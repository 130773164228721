"use client";
import React from "react";
import * as _Builtin from "./_Builtin";

export function FeaturedSlideImage({
  as: _Component = _Builtin.Image,
  featuredSlideImageImage = "https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/656beec750c7f84477e2c301_Rectangle%2093.png",
}) {
  return (
    <_Component
      className="featured_slide-image"
      loading="lazy"
      width="auto"
      height="auto"
      alt=""
      src={featuredSlideImageImage}
    />
  );
}
