"use client";
import React from "react";
import * as _Builtin from "./_Builtin";
import { HeroSubCardComponent } from "./HeroSubCardComponent";

export function HeroSubCardComponentContainer({
  as: _Component = _Builtin.Block,
}) {
  return (
    <_Component className="hero-card-container---component" tag="div">
      <_Builtin.Block className="hero-card-container" tag="div">
        <HeroSubCardComponent
          description="Schedule rent payments, submit maintenance requests, renew your lease and more."
          icon="https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/656bd2a03bf3058f0b5261d9_residents.svg"
          title="Residents"
          ctaText="Resident Login →"
          linkText="Resident Login"
          linkLink={{
            href: "/residents/",
          }}
        />
        <HeroSubCardComponent
          icon="https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/657b1b84ca3edfcfc1cc6b1a_chart.svg"
          title="Applicants"
          description="Welcome future neighbors! Find your new home and apply online."
          linkLink={{
            href: "/applicants/",
          }}
          linkText="Find Your Home"
          ctaText="Find Your Home →"
        />
        <HeroSubCardComponent
          description="View our current job openings and apply for positions online."
          icon="https://cdn.prod.website-files.com/656bce4e0dae8369c9788229/657b1b9200880656feb34777_bag.svg"
          linkText="Join Our Team"
          linkLink={{
            href: "https://careers-sares-regis.icims.com/jobs/intro?hashed=-435627079&mobile=false&width=940&height=500&bga=true&needsRedirect=false&jan1offset=-360&jun1offset=-300",
          }}
        />
      </_Builtin.Block>
    </_Component>
  );
}
